import {
  cyberTerritoryDict,
  getInfo,
  sendVerifyCodeToEmail,
  registerEmailCheck,
  registerCustomer,
  customerEdit,
  sendEmailUpdatePassword,
  changePassword,
  checkUpdatePasswordLink,
  sendLoginVerifyCodeToEmail,
  getSTSToken,
  listByTenantId,
  listByTenantIdImg,
  submit,
  changePasswordEmail,
  sendEmailLogin,
  saveRecord,
  recordListPage,
  deleteRecord,
  requestClientId,
  searchVersion,
  getPrintImage,
  getShareProductAccount,
  sendShareProductEmail,
  eventRecord
} from "../services/api/system";
const jsencrypt = useJsencrypt();
class systemInteractor {
  static getInstance() {
    return this._instance
  }
  static _instance = new systemInteractor()

  /**
   * 查询cyber地区字典
   * @param {level}
   * @param {parentId}
   * @param {nameOrCode}
   */
  cyberTerritoryDict(level, id) {
    return cyberTerritoryDict({
      level: level,
      parentId: id,
      nameOrCode: ""
    })
  }

  /**
   * 根据邮编获取 国家, 州/省 数据
   * @param {zipcode} 邮编
   */
  getInfo(zipcode) {
    return getInfo({ zipcode: zipcode })
  }

  /**
   * 生成验证码，并发送邮箱
   * @param {email} 邮箱
   */
  sendVerifyCodeToEmail(email) {
    return sendVerifyCodeToEmail({ email: email })
  }

  /**
   * 生成登录验证码，并发送邮箱
   * @param {email} 邮箱
   */
  sendLoginVerifyCodeToEmail(email, browserType) {
    return sendLoginVerifyCodeToEmail({ email, browserType })
  }

  /**
   * 校验邮箱下是否存在客户
   * @param {email} 邮箱
   */
  registerEmailCheck(email) {
    return registerEmailCheck({ email: email })
  }

  /**
   * 客户注册
   * @param {companyName} 公司名称
   * @param {companyTitle} 公司title
   * @param {email} 邮箱
   * @param {password} 登录密码
   * @param {remark} 备注
   * @param {verifyCode} 验证码
   * @param {customerContact} 客户联系信息
   * @param {customerIndustry} 客户行业信息
   */
  registerCustomer(params) {
    return registerCustomer(params)
  }

  /**
   * 客户编辑
   */
  customerEditApi(params) {
    return customerEdit(params)
  }

  /**
   * 发送邮箱重置密码
   */
  sendEmailUpdatePassword(email) {
    return sendEmailUpdatePassword({ loginName: email })
  }

  /**
   * 重置密码
   * @param {param}
   * @param {password}
   */
  changePassword(param, form) {
    return changePassword({
      param: param,
      password: this.getEncryptData(1, form.password),
      newPassword: this.getEncryptData(1, form.newPassword)
    })
  }
  /**
   * 获取加密数据方法
   * @param {*} count
   * @param {*} configData
   * @returns
   */
  getEncryptData(count, configData) {
    count += 1
    let code = jsencrypt.encryptData(configData)
    const len = atob(code).length
    if (count > 3 || len % 8 === 0) {
      return code
    }
    return getEncryptData(count, configData)
  }
  /**
   * 判断密码是否失效
   * @param {param}
   */
  checkUpdatePasswordLinkApi(param) {
    return checkUpdatePasswordLink({
      param: param
    })
  }

  /**
   * 获取阿里云密钥
   */
  getSTSTokenApi() {
    return getSTSToken()
  }

  // 获取类目列表
  async listByTenantIdApi(size) {
    let res = await listByTenantId(size);
    return res;
  }
  // 获取轮播图列表
  async listByTenantIdImgApi() {
    let res = await listByTenantIdImg()
    return res
  }
  // 提交留言
  async messageSubmitApi(data) {
    let res = await submit(data)
    return res
  }
  // 修改密码
  async changePasswordEmail(data) {
    let res = await changePasswordEmail(data)
    return res
  }

  // 发送一次性登录链接密码
  async sendEmailLoginApi(loginName) {
    return sendEmailLogin({
      loginName
    })
  }

  /**
   * 客户浏览记录保存
   * @param {productId} 产品id
   */
  saveRecordApi(params) {
    const { productId } = params
    return saveRecord({ productId })
  }

  /**
   * 客户浏览记录查询
   * @param {current} 页码
   * @param {size} 页数
   */
  recordListPageApi(params) {
    const { current, size } = params
    return recordListPage({ current, size })
  }

  /**
   * 客户浏览记录删除
   * @param {recordIds} 浏览记录ID集合
   */
  deleteRecordApi(params) {
    const { recordIds } = params
    return deleteRecord({ recordIds })
  }

  /**
   *  生成匿名用户唯一标记
   */
  requestClientIdApi() {
    return requestClientId()
  }

  /**
   *  获取搜索执行版本
   */
  searchVersionApi() {
    return searchVersion()
  }

  // 获取分享产品发送邮件地址
  getShareProductAccountApi() {
    return getShareProductAccount()
  }

  sendShareProductEmailApi(data) {
    const { title, content, receiveList } = data
    return sendShareProductEmail({
      title,
      content,
      receiveList: receiveList.split(",").filter((email) => {
        return email
      })
    })
  }

  // 首页获取印刷图片
  getPrintImageApi(deviceType) {
    return getPrintImage({deviceType});
  }

  /**
   * 
   * @param {*} eventName 事件名称(下载模版、下载高清大图、下载单页、分享产品、上传设计图、order tracking)
   * @param {*} businessTag 业务标记目前order tracking?后面内容数据 
   * @returns 
   */
  eventRecordApi({eventName, businessTag}) {
    return eventRecord({eventName, businessTag})
  }
}

export default systemInteractor.getInstance()
