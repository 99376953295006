import { listHomeReviews,registerMailRule,registerMailDnsCheck,customerCreditCards,customerCreditDelete } from "../services/api/customer";

class customerInteractor {
  static getInstance() {
    return this._instance;
  }
  static _instance = new customerInteractor();

  /**
   * 首页评价列表
   */
  listHomeReviewsApi() {
    return listHomeReviews();
  }

  // 邮箱校验规则
  registerMailRuleApi(){
    return registerMailRule();
  }

  // 邮箱DNS校验
  registerMailDnsCheckApi(mail){
    return registerMailDnsCheck(mail);
  }

  customerCreditCardsApi(){
    return customerCreditCards();
  }

  customerCreditDeleteApi(cardId){
    return customerCreditDelete(cardId);
  }
}

export default customerInteractor.getInstance();
