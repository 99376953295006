import { useGlobalStore } from "~/stores";
import { getClientInfo } from '~/utils';

// 非法访问的UA黑名单
const blacklist = ['Blackbox', 'curl', 'Site24x7', 'python-requests', 'compatible']

export default defineNuxtRouteMiddleware( async (to) => {
  // 有效期一年
  const maxAge = 60 * 60 * 24 * 365
  // 进入页面生成 client_id 用于记录访客信息
  const requestClientId = useCookie('request_client_id', { maxAge })
  const uaflag = useCookie('uaflag2', { maxAge })
  if (!requestClientId.value || !uaflag.value) {
    const config = useRuntimeConfig()
    const event = useRequestEvent()
    if (event) {
      try {
        const ua = event.req.headers['user-agent']
        if (!ua) {
          return
        }
        // 黑名单过滤
        if (blacklist.some(item => ua.toLocaleLowerCase().includes(item.toLocaleLowerCase()))) {
          return
        }

        const { data } = await useAsyncData('requestClientId', () => {
          return $fetch(`${config.public.serverBaseUrl}system/anonymous/requestClientId`, {
            method: "GET",
            headers: {
              ...event.req.headers,
              ...getClientInfo(ua),
              tenantId: config.public.tenantId,
              Systemcode: "mall",
              erpTenantId: config.public.erpTenantId,
            },
            credentials: 'include'
          })
        })

        uaflag.value = 1
        requestClientId.value = data?.value?.data
      } catch (error) {}
    }
  }
  
  // 只有RP才用新版首页
  useGlobalStore().setNewVersion(import.meta.env.VITE_NODE_Mall === 'RP')
});
