import request from "../request";
export function listHomeReviews() {
  return request({
    url: `/customer/reviews/listHomeReviews`,
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 邮箱校验规则
export function registerMailRule() {
  return request({
    url: `/customer/customer/registerMailRule`,
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 邮箱DNS校验
export function registerMailDnsCheck(mail) {
  return request({
    url: `/customer/customer/registerMailDnsCheck`,
    method: "post",
    body: { mail },
  });
}

// 支付卡信息
export function customerCreditCards() {
  return request({
    url: `/customer/customerCredit/myCards`,
    method: "post",
    headers: {
      decode: true
    }
  });
}

// 删除支付卡信息
export function customerCreditDelete(cardId) {
  return request({
    url: `/customer/customerCredit/delete/${cardId}`,
    method: "get",
  });
}